<mat-chip-list class="filter-tags-list adap-form-input" #chipList aria-label="">
  <mat-chip
    *ngFor="let filterTag of filterTags"
    (removed)="remove(filterTag)"
    [selectable]="true"
    [removable]="true"
  >
    {{ filterTag }}
    <button matChipRemove [attr.aria-label]="'remove ' + filterTag">
      <mat-icon>cancel</mat-icon>
    </button>
  </mat-chip>
  <input
    class="tags-input"
    [placeholder]="filterTags.size === 0 ? placeholder : ''"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    [matChipInputAddOnBlur]="true"
    (matChipInputTokenEnd)="add($event)"
  />
</mat-chip-list>

<div class="clear-button-wrapper">
  <button
    class="adap-btn-blank clear-button"
    title="clear"
    *ngIf="filterTags != null && filterTags.size > 0"
    (click)="onClearFilterTagsClicked()"
  >
    <mat-icon svgIcon="cvx-close"></mat-icon>
  </button>
</div>

<button class="adap-btn-filled search-button" title="clear">
  <mat-icon>search</mat-icon>
</button>
