import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  private iconNames: string[] = [
    'attach-file',
    'cards',
    'calendar',
    'checklist-checkmark',
    'checklist-search',
    'checkmark',
    'checkmark-circle',
    'circle-informational',
    'close',
    'download',
    'envelope',
    'envelope-filled',
    'filter',
    'hamburger',
    'info-circle',
    'left',
    'pencil',
    'person',
    'person-check',
    'person-empty',
    'plus-circle',
    'refresh',
    'right',
    'trash',
    'upload',
    'warning',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.addIcons(this.iconNames);
  }

  addIcons(names: string[]) {
    const iconsDirectoryPath = '../assets/cvx-icons/';
    const extension = '.svg';

    names.forEach((name) =>
      this.matIconRegistry.addSvgIcon(
        'cvx-' + name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          iconsDirectoryPath + name + extension
        )
      )
    );
  }
}
