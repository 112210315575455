import { CellClassParams, ColDef } from 'ag-grid-community';

export const AdapCellClassRules = {
  'ag-estimate-cell': (params: CellClassParams) =>
    params.colDef.type?.includes('estimate') ?? false,
  'ag-center-aligned-cell': (params: CellClassParams) =>
    params.colDef.type?.includes('centerAligned') ?? false,
  'value-found-cell': (params: CellClassParams) => {
    const quickFilter = (params.api as any)?.filterManager.quickFilter;
    if (quickFilter === '' || quickFilter == null) return false;
    return params.value
      ?.toString()
      .toLowerCase()
      .includes(quickFilter.toLowerCase());
  },
};

export const AdapColumnTypes: { [key: string]: ColDef } = {
  estimate: {
    headerClass: 'ag-grid-estimate-discipline-header',
    cellClassRules: AdapCellClassRules,
  },
  centerAligned: {
    headerClass: 'ag-center-aligned-cell',
    cellClassRules: {
      'ag-center-aligned-cell': (params: CellClassParams) =>
        params.colDef.type?.includes('centerAligned') ?? false,
    },
  },
};
