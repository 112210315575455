import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { EditValueDialogData } from '../../models/form-dialog-data';
import { EditValueDialog } from '../dialog-with-form/edit-value-dialog.component';

@Component({
  selector: 'app-value-edit-with-dialog',
  templateUrl: './value-edit-with-dialog.component.html',
  styleUrls: ['./value-edit-with-dialog.component.scss'],
})
export class ValueEditWithDialogComponent {
  @Input('tooltip') tooltip: string = '';
  @Input('text') text: string = '';
  @Input('formDialogData') formDialogData!: EditValueDialogData;
  @Input('disabled') disabled: boolean = false;

  private dialogRef!: MatDialogRef<EditValueDialog>;

  constructor(private dialog: MatDialog) {}

  openEditDialog(): void {
    this.dialogRef = this.dialog.open(EditValueDialog, {
      panelClass: ['generic-form-dialog', this.formDialogData.cssClass.trim()],
      maxWidth: 'unset',
      data: this.formDialogData,
      disableClose: true,
    });

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.formDialogData.onCancel?.());
  }
}
