import { DADeliverableOrigin } from '../models/autogenerated';
import { OriginChipMap } from './constants';

export interface ChipInfo {
  tooltipText: string;
  className: string;
}

export function createChipMap<T extends string>(
  entries: Record<T, ChipInfo>
): Map<T, ChipInfo> {
  const map = new Map<T, ChipInfo>();

  for (const [key, entry] of Object.entries(entries) as [T, ChipInfo][]) {
    map.set(key, {
      tooltipText: entry.tooltipText,
      className: entry.className,
    });
  }

  return map;
}

export function getClassName(value: string): string {
  const info = OriginChipMap.get(value as DADeliverableOrigin);
  return info?.className ?? '';
}

export function getTooltipText(value: string): string {
  const info = OriginChipMap.get(value as DADeliverableOrigin);
  return info?.tooltipText ?? value;
}
