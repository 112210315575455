import { Component, Inject, Input } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.state';
import { importExcelFile } from '../../screening.functions';

@Component({
  selector: 'app-import-excel-dialog',
  templateUrl: './import-excel-dialog.component.html',
  styleUrls: ['./import-excel-dialog.component.scss'],
})
export class ImportExcelDialogComponent {
  readonly deliverableType: string;
  onlyAdd: boolean = true;
  insert: boolean = false;
  update: boolean = false;
  delete: boolean = false;

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<ImportExcelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { deliverableType: string }
  ) {
    this.deliverableType = data.deliverableType;
  }

  unclickIncrementalOptions() {
    this.insert = false;
    this.update = false;
    this.delete = false;
  }

  onClick(e: any) {
    e.preventDefault();

    this.onlyAdd = true;
    this.unclickIncrementalOptions();
  }

  onCheckboxChanged(action: string, check: MatCheckboxChange) {
    if (check.checked === false) {
      this.onlyAdd =
        this.insert === false && this.update === false && this.delete === false;

      return;
    }

    if (action === 'onlyAdd') {
      this.unclickIncrementalOptions();
    } else {
      this.onlyAdd = false;
    }
  }

  closeWindow(): void {
    this.dialogRef.close();
  }

  async importExcel(fileInput: HTMLInputElement | null) {
    importExcelFile(
      fileInput,
      this.store,
      this.onlyAdd,
      this.insert,
      this.delete,
      this.update,
      this.deliverableType
    );

    this.closeWindow();
  }
}
