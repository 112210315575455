import { Pipe, PipeTransform } from '@angular/core';
import { ProjectViewModel } from '../models/autogenerated';

@Pipe({
  name: 'ProjectTypeString',
})
export class ProjectTypeStringPipe implements PipeTransform {
  transform(project: ProjectViewModel): any {
    if (project.onShore === true) {
      return 'onshore';
    } else if (project.offShore === true) {
      return 'offshore';
    }
    return '';
  }
}
