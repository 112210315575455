import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import {
  Action,
  ActionReducer,
  MetaReducer,
  Store,
  StoreModule,
} from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  Keys,
  localStorageSync,
  rehydrateApplicationState,
} from 'ngrx-store-localstorage';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppState } from './app.state';
import { storageActionType, StoragePayloadSync } from './app.state.actions';
import { MsalComponent } from './core/components/msal.component';
import { CoreModule } from './core/core.module';
import {
  loadAccountInfo,
  loadUserPhoto,
  loadUserProfile,
} from './core/store/user/user.actions';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './core/utils/msal.setup';
import { NewWorkplanFlowModule } from './roles/da-engineer/new-workplan/new-workplan-flow.module';
import { AgGridConfigModule } from './shared/ag-grid-config/ag-grid-config.module';
import { ApiErrorHandler } from './shared/handlers/api-error.handler';
import { CustomRouteReuseStrategy } from './shared/services/custom-route-reuse-strategy.service';
import { SharedModule } from './shared/shared.module';

const keys = [
  'cacheClear',
  'mainPageState',
  'projectPageView',
  'agGridConfig',
  'peerPoolPageView',
  'deliverableManagerConfig',
  'observationToolConfig',
  'workplanConfig',
  'sme-hours',
  'observationToolDashboardPageView',
];

export function localStorageSyncReducer<T, V extends Action = Action>(
  reducer: ActionReducer<any>
): ActionReducer<T, V> {
  return (state, action) => {
    const isPayloadAction = 'payload' in action;
    const payloadAction: StoragePayloadSync =
      action as any as StoragePayloadSync;

    // This allows to rehydrate between tabs if the action is called
    if (
      action.type === storageActionType &&
      isPayloadAction &&
      keys.includes(payloadAction.payload as string)
    ) {
      const rehydratedState = rehydrateApplicationState(
        [payloadAction.payload] as Keys,
        localStorage,
        (k) => k,
        true
      );
      return { ...state, ...rehydratedState };
    }

    return localStorageSync({
      rehydrate: true,
      // CAUTION! WARNING! All modules that have the feature reducers
      // for parts of the store listed here must all be lazy loaded!
      keys,
    })(reducer)(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    NewWorkplanFlowModule,
    AgGridConfigModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        metaReducers,
      }
    ),
    CoreModule,
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ApiErrorHandler,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<AppState>) => {
        return () => {
          store.dispatch(loadAccountInfo());
        };
      },
      multi: true,
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<AppState>) => {
        return () => {
          store.dispatch(loadUserPhoto());
        };
      },
      multi: true,
      deps: [Store],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<AppState>) => {
        return () => {
          store.dispatch(loadUserProfile());
        };
      },
      multi: true,
      deps: [Store],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalComponent],
})
export class AppModule {}
