import { DADeliverableOrigin } from '../models/autogenerated';
import { createChipMap } from './chip-utils';

export class Constants {
  static readonly ADAP_SHORT_DATE = 'MM/dd/yyyy';
  static readonly GUID_DEFAULT_VALUE = '00000000-0000-0000-0000-000000000000';
  static readonly USER_PHOTO_MINIATURE_SIZE = '48x48';
  static readonly REQUIRED_MAPPING_ERROR: string =
    'required,this field is required,maxLength,maximum length of field reached';
  static readonly YES = 'YES';
  static readonly NO = 'NO';
  static readonly NO_DATE_SET = '[no date set]';
  static readonly ITEMS_REQUIRED_YOUR_ATTENTION =
    'There are items that require your attention';
}

export class UserRoles {
  static readonly DA_ENGINEER = 'DAEngineer';
  static readonly CHAPTER_MANAGER = 'ChapterManager';
  static readonly SME = 'SME';
  static readonly PROJECT_CONTACT = 'ProjectContact';
}

export const UserRolesDisplayLabel: Map<string, string> = new Map([
  [UserRoles.DA_ENGINEER, 'Design Assurance'],
  [UserRoles.CHAPTER_MANAGER, 'Chapter Manager'],
  [UserRoles.SME, 'SME'],
  [UserRoles.PROJECT_CONTACT, 'Project Contact'],
]);

export const QUICKFILTER_INPUT_PLACEHOLDER = "i'm looking for...";

export class Colors {
  static readonly WHITE = 'white';
  static readonly BLUE = 'blue';
  static readonly BLUE_DARK = 'blue-dark';
  static readonly BLUE_SHADE_1 = 'blue-shades-1';
  static readonly BLUE_SHADE_2 = 'blue-shades-2';
  static readonly BLUE_SHADE_3 = 'blue-shades-3';
  static readonly BLUE_SHADE_4 = 'blue-shades-4';
  static readonly BLUE_SHADE_5 = 'blue-shades-5';
  static readonly GREEN_MEDIUM = 'green-medium';
  static readonly GREEN_DARK = 'green-dark';
  static readonly TEAL = 'teal';
  static readonly TEAL_SHADE_1 = 'teal-shades-1';
  static readonly TEAL_SHADE_2 = 'teal-shades-2';
  static readonly TEAL_SHADE_3 = 'teal-shades-3';
  static readonly TEAL_SHADE_4 = 'teal-shades-4';
  static readonly TEAL_SHADE_5 = 'teal-shades-5';
  static readonly ORANGE_LIGHT = 'orange-light';
  static readonly BURGUNDY = 'burgundy';
}

export const OriginChipMap = createChipMap<DADeliverableOrigin>({
  [DADeliverableOrigin.AdHoc]: {
    tooltipText: 'Ad Hoc',
    className: 'adap-background-color-blue-dark',
  },
  [DADeliverableOrigin.RiskAssessment]: {
    tooltipText: 'Risk Assessment',
    className: 'adap-background-color-blue',
  },
  [DADeliverableOrigin.ManuallyAdded]: {
    tooltipText: 'Manually Added',
    className: 'adap-background-color-teal',
  },
});
