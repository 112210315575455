import { Component, Input, OnInit } from '@angular/core';
import { ChipInfo, getClassName, getTooltipText } from '../../utils/chip-utils';

@Component({
  selector: 'app-origin-chip',
  templateUrl: './origin-chip.component.html',
  styleUrls: ['./origin-chip.component.scss'],
})
export class OriginChipComponent implements OnInit {
  @Input() value!: string;
  @Input() chipMap!: Map<string, ChipInfo>;
  className: string = '';
  tooltipText: string = '';

  ngOnInit(): void {
    this.className = getClassName(this.value);
    this.tooltipText = getTooltipText(this.value);
  }
}
