import { createReducer, on } from '@ngrx/store';
import {
  box,
  Boxed,
  createFormGroupState,
  disable,
  enable,
  FormGroupState,
  markAsSubmitted,
  onNgrxForms,
  onNgrxFormsAction,
  setValue,
  SetValueAction,
  updateGroup,
  validate,
  wrapReducerWithFormStateUpdate,
} from 'ngrx-forms';
import { maxLength, required } from 'ngrx-forms/validation';
import {
  BusinessUnitViewModel,
  ProjectViewModel,
} from 'src/app/shared/models/autogenerated';
import { NonNegativeFloat2DecimalsOrInteger } from 'src/app/shared/store/custom-validators/pattern-for-numbers-validator';
import { DESCRIPTION_MAX_LENGTH } from '../../workplan/workplan.constants';
import {
  DA_DELIVERABLES,
  PROJECT_DELIVERABLES,
} from '../new-workplan-flow.constants';
import {
  clearNewWorkplan,
  createNewWorkplanForProjectSuccess,
  createNewWorkplanSuccess,
  setBusinessUnits,
  setCurrentPhaseId,
  setEditProjectToNewProjectDialog,
  setNewProjectAllPhases,
  setNewProjectAvailablePhases,
  setNewProjectDALead,
} from './new-project.actions';

const FORM_ID = 'ADAP_NEW_WORK_PLAN_FORM';
const LEAD_BY_LIST: string[] = [DA_DELIVERABLES, PROJECT_DELIVERABLES];

export interface NewWorkplanForm {
  projectId?: string | null;
  name: string | null;
  description: string | null;
  nojv: boolean | null;
  onShore: boolean | null;
  offShore: boolean | null;
  phaseId: string | null;
  themes: Boxed<string[]>;
  businessUnitId: string | null;
  daLead: string | null;
  daManager: string | null;
  daSupport: string | null;
  projectContacts: Boxed<(string | null)[]>;
  plannedStartDate: string | null;
  plannedCompletionDate: string | null;
  copyFromPhase: string | null;
  copyDADeliverables: boolean;
  copyProjectDeliverables: boolean;
  copyDeliverableMappings: boolean;
  copyPeerPool: boolean;
  copyRiskAssessment: boolean;
  leadBy: string | null;
  estimateUSD: number | null;
  buffer: number | null;
  ipimsDomain: string | null;
  userAnonymity: boolean;
}

export interface NewWorkplanState {
  newWorkplanForm: FormGroupState<NewWorkplanForm>;
  allPhases: string[];
  availablePhases: string[];
  canCopyWorkplanFrom2B: boolean;
  existingProject: ProjectViewModel | null;
  createdProject: ProjectViewModel | null;
  businessUnits: BusinessUnitViewModel[] | null;
  leadByList: string[];
}

const validateForm = updateGroup<NewWorkplanForm>({
  name: validate(required),
  description: validate(required, maxLength(DESCRIPTION_MAX_LENGTH)),
  nojv: validate(required),
  onShore: validate(required),
  offShore: validate(required),
  themes: validate(required),
  daLead: validate(required),
  leadBy: validate(required),
  phaseId: validate(required),
  copyDeliverableMappings: (copyDeliverableMappings, newWorkplanForm) => {
    const isSubmitted =
      newWorkplanForm?.controls?.copyDADeliverables.isSubmitted ?? false;
    const disableCopyDeliverableMappings =
      isSubmitted ||
      newWorkplanForm?.controls?.copyDADeliverables.value !== true ||
      newWorkplanForm?.controls?.copyProjectDeliverables.value !== true;

    let copyDeliverableMappingsControlOutput = copyDeliverableMappings;
    if (!isSubmitted) {
      if (disableCopyDeliverableMappings) {
        copyDeliverableMappingsControlOutput = disable(
          copyDeliverableMappingsControlOutput
        );
        copyDeliverableMappingsControlOutput = setValue(
          copyDeliverableMappingsControlOutput,
          false
        );
      } else {
        copyDeliverableMappingsControlOutput = enable(
          copyDeliverableMappingsControlOutput
        );
      }
    }
    return copyDeliverableMappingsControlOutput;
  },
  estimateUSD: validate(required, NonNegativeFloat2DecimalsOrInteger()),
  buffer: validate(required),
});

const initialState: NewWorkplanState = {
  newWorkplanForm: createFormGroupState<NewWorkplanForm>(FORM_ID, {
    projectId: null,
    name: null,
    description: null,
    nojv: null,
    phaseId: null,
    onShore: null,
    offShore: null,
    themes: box([]),
    businessUnitId: null,
    daLead: null,
    daManager: null,
    daSupport: null,
    projectContacts: box([]),
    plannedStartDate: null,
    plannedCompletionDate: null,
    copyFromPhase: null,
    copyDADeliverables: false,
    copyProjectDeliverables: false,
    copyDeliverableMappings: false,
    copyPeerPool: false,
    copyRiskAssessment: false,
    leadBy: DA_DELIVERABLES,
    estimateUSD: null,
    buffer: null,
    ipimsDomain: null,
    userAnonymity: true,
  }),
  availablePhases: [],
  canCopyWorkplanFrom2B: false,
  allPhases: [],
  existingProject: null,
  createdProject: null,
  businessUnits: [],
  leadByList: LEAD_BY_LIST,
};

function getNextAvailablePhase(state: NewWorkplanState): string | undefined {
  const project = state.existingProject;
  if (project == null) {
    return undefined;
  }

  var collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  const allPhasesSorted = [...state.allPhases].sort(collator.compare);

  return allPhasesSorted.find(
    (phase) =>
      allPhasesSorted.indexOf(phase) >
      (allPhasesSorted.indexOf(project.currentPhase?.phaseId!) ?? -1)
  );
}

const rawNewProjectReducer = createReducer(
  initialState,
  onNgrxForms(),
  on(clearNewWorkplan, () => initialState),

  on(setCurrentPhaseId, (state, { phaseId }) => ({
    ...state,
    newWorkplanForm: setValue(<NewWorkplanForm>{
      ...state.newWorkplanForm.value,
      phaseId: phaseId,
    })(state.newWorkplanForm),
  })),

  on(setEditProjectToNewProjectDialog, (state, { project }) => {
    return {
      ...state,
      newWorkplanForm: createFormGroupState<NewWorkplanForm>(FORM_ID, {
        ...state.newWorkplanForm.value,
        projectId: project?.id,
        name: project?.name ?? null,
        description: project?.description ?? null,
        nojv: project?.nojv ?? null,
        onShore: project?.onShore ?? null,
        offShore: project?.offShore ?? null,
        themes: box(project?.currentPhase?.themes ?? []),
        businessUnitId: project?.businessUnitId ?? null,
        daLead: project?.currentPhase?.daLead ?? null,
        daManager: project?.currentPhase?.daManager ?? null,
        daSupport: project?.currentPhase?.daSupport ?? null,
        projectContacts: box(project?.currentPhase?.projectContacts ?? []),
        leadBy: project?.currentPhase?.leadBy ?? null,
        ipimsDomain: project?.currentPhase?.ipimsDomain ?? null,
      }),
      existingProject: project,
      canCopyWorkplanFrom2B: (
        project?.phases
          .map((projectPhase) => projectPhase.phaseId)
          .filter((s): s is string => s != null) ?? []
      ).includes('2B'),
    };
  }),

  on(setNewProjectAvailablePhases, (state) => {
    const nextPhase = getNextAvailablePhase(state);
    return {
      ...state,
      newWorkplanForm: createFormGroupState<NewWorkplanForm>(FORM_ID, {
        ...state.newWorkplanForm.value,
        copyFromPhase: getCopyFromPhase(state.canCopyWorkplanFrom2B, nextPhase),
        phaseId: nextPhase ?? null,
      }),
      availablePhases: nextPhase
        ? [nextPhase]
        : getPhasesNotIncludedInTheProject(state),
    };
  }),

  on(setNewProjectDALead, (state, { daLead }) => ({
    ...state,
    newWorkplanForm: createFormGroupState<NewWorkplanForm>(FORM_ID, {
      ...state.newWorkplanForm.value,
      daLead,
    }),
  })),

  on(setBusinessUnits, (state, { businessUnits }) => ({
    ...state,
    businessUnits: businessUnits,
  })),

  on(setNewProjectAllPhases, (state, { allPhases }) => ({
    ...state,
    allPhases,
  })),

  on(createNewWorkplanSuccess, (state, { project }) => ({
    ...state,
    newWorkplanForm: disable(state.newWorkplanForm),
    createdProject: project,
  })),

  on(createNewWorkplanForProjectSuccess, (state, { project }) => ({
    ...state,
    newWorkplanForm: markAsSubmitted(disable(state.newWorkplanForm)),
    createdProject: project,
  })),

  onNgrxFormsAction(SetValueAction, (state, action) => {
    let newWorkplanForm = state.newWorkplanForm;

    newWorkplanForm = setValue(<NewWorkplanForm>{
      ...newWorkplanForm.value,
      copyFromPhase: getCopyFromPhase(
        state.canCopyWorkplanFrom2B,
        state.newWorkplanForm.controls?.phaseId?.value
      ),
    })(newWorkplanForm);

    if (action.controlId === `${FORM_ID}.onShore`) {
      newWorkplanForm = setValue(<NewWorkplanForm>{
        ...newWorkplanForm.value,
        offShore: !newWorkplanForm.value.onShore,
      })(newWorkplanForm);
    }

    if (action.controlId === `${FORM_ID}.phaseId`) {
      newWorkplanForm = setValue(<NewWorkplanForm>{
        ...newWorkplanForm.value,
        copyWorkplanFrom2B: false,
      })(newWorkplanForm);
    }

    return {
      ...state,
      newWorkplanForm: newWorkplanForm,
    };
  })
);

function getCopyFromPhase(
  canCopyFrom2B: boolean,
  phaseId: string | null | undefined
) {
  const isPhase2CSelected = phaseId === '2C';
  return isPhase2CSelected && canCopyFrom2B ? '2B' : null;
}

function getPhasesNotIncludedInTheProject(state: NewWorkplanState) {
  return state.allPhases.filter(
    (ph) =>
      !state.existingProject?.phases
        .map((projectPhase) => projectPhase.phaseId)
        .includes(ph)
  );
}

export const newWorkplanReducer = wrapReducerWithFormStateUpdate(
  rawNewProjectReducer,
  (s) => s.newWorkplanForm,
  validateForm
);
