<app-input-label
  *ngIf="label != null"
  [label]="label"
  [labelFor]="labelFor"
  [isRequired]="isRequired"
  [characterCounterLength]="characterCounterLength"
  [characterCounterMaxLength]="characterCounterMaxLength"
  [info]="info"
  [ngClass]="{ 'adap-input-label-bold': boldLabel }"
  [copy]="copy"
  (copyEvent)="copyEvent.emit()"
></app-input-label>
<ng-content></ng-content>
<span
  *ngFor="
    let error of inputValidationContainer
      | FormErrorsIterable : REQUIRED_MAPPING_ERROR
  "
  class="field-invalid-text"
>
  {{ error }}
</span>
