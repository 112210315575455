import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { FullScreenState } from '../models/full-screen-state';
import { selectUrl } from '../store/shared.selectors';

/**
 * This service handles the full screen events. It can handle multiple
 * full screens in the same view, but then each fullscreen wrapper + toggle + table
 * must have different fullScreenIds for the service to work correctly.
 */
@Injectable({
  providedIn: 'root',
})
export class FullscreenManagerService {
  private readonly fullScreenStates = new Map<
    string,
    BehaviorSubject<FullScreenState>
  >();

  constructor(private store: Store<AppState>) {}

  /**
   * If only one fullscreen feature exists in the view,
   * the id can be left out because the service will figure it
   * out from the current route. Otherwise, for multiple full-screens
   * in the same view, the fullScreenId must be provided for each one.
   *
   * The provided observables are removed automatically when there are
   * no longer subscribers.
   * @param fullScreenId
   * @returns
   */
  async subscribeToFullScreen(
    fullScreenId: string = '',
    isFullScreen: boolean = false
  ) {
    const id = await this.getFullScreenId(fullScreenId);
    return this.handleSubscribeToFullScreen(id, isFullScreen);
  }

  toggle(fullScreenId: string) {
    const fullScreenObs$ = this.fullScreenStates.get(fullScreenId);
    fullScreenObs$?.pipe(take(1)).subscribe((state) => {
      fullScreenObs$.next({ ...state, isFullScreen: !state.isFullScreen });
    });
  }

  setFullscreen(
    fullScreenId: string,
    fullscreen: boolean,
    isDisposing: boolean
  ) {
    const fullScreenObs$ = this.fullScreenStates.get(fullScreenId);
    fullScreenObs$?.next({
      fullScreenId,
      isFullScreen: fullscreen,
      isDisposing,
    });
  }

  private async getFullScreenId(fullScreenId: string) {
    if (fullScreenId != null && fullScreenId.length > 0) {
      return fullScreenId;
    }

    return await this.store.select(selectUrl).pipe(take(1)).toPromise();
  }

  private handleSubscribeToFullScreen(
    fullScreenId: string,
    isFullScreen: boolean
  ) {
    const fullScreenObs$ = this.fullScreenStates.get(fullScreenId);

    if (fullScreenObs$ != null) {
      return fullScreenObs$ as Observable<FullScreenState>;
    }

    const fullScreenInitialState = {
      fullScreenId,
      isFullScreen,
      isDisposing: false,
    } as FullScreenState;

    return this.createAndReturnObservable(
      fullScreenId,
      fullScreenInitialState
    ) as Observable<FullScreenState>;
  }

  private createAndReturnObservable(
    obsId: string,
    initialState: FullScreenState
  ): BehaviorSubject<any> {
    const subj = new BehaviorSubject<any>(initialState).pipe(
      finalize(() => {
        if ((subj as any).destination.observers.length <= 1) {
          subj.unsubscribe();
          this.fullScreenStates.delete(obsId);
        }
      })
    ) as BehaviorSubject<any>;
    this.fullScreenStates.set(obsId, subj);
    return subj;
  }
}
