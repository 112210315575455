import { Component, Input, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { DeliverableManagerDADeliverableTableComponent } from '../deliverable-manager-da-deliverable-table/deliverable-manager-da-deliverable-table.component';
import { DeliverableManagerProjectDeliverableTableComponent } from '../deliverable-manager-project-deliverable-table/deliverable-manager-project-deliverable-table.component';

@Component({
  selector: 'app-deliverable-manager-matched-filter',
  templateUrl: './deliverable-manager-matched-filter.component.html',
})
export class DeliverableManagerMatchedFilterComponent {
  @Input()
  projectDeliverableTableComponent!: DeliverableManagerProjectDeliverableTableComponent;
  @Input()
  daDeliverableTableComponent!: DeliverableManagerDADeliverableTableComponent;
  @ViewChild('displayViewMatSelect') displayViewMatSelect!: MatSelect;

  MATCHED = 'matched';
  UNMATCHED = 'unmatched';
  displayViewSelected: string[] = [];
  displayMatched: boolean = false;
  displayUnmatched: boolean = false;

  private isFilterMatched(
    tableComponent:
      | DeliverableManagerProjectDeliverableTableComponent
      | DeliverableManagerDADeliverableTableComponent
  ) {
    if (tableComponent == null) {
      return true;
    }

    const mapFilter = tableComponent.agGrid.api!.getFilterModel().map;
    return (
      mapFilter != null &&
      mapFilter.type === 'greaterThan' &&
      mapFilter.filter == 0
    );
  }

  private isFilterUnmatched(
    tableComponent:
      | DeliverableManagerProjectDeliverableTableComponent
      | DeliverableManagerDADeliverableTableComponent
  ) {
    if (tableComponent == null) {
      return true;
    }

    const mapFilter = tableComponent.agGrid.api!.getFilterModel().map;
    return (
      mapFilter != null && mapFilter.type === 'equals' && mapFilter.filter == 0
    );
  }

  filterChanged() {
    this.displayMatched =
      this.isFilterMatched(this.projectDeliverableTableComponent) &&
      this.isFilterMatched(this.daDeliverableTableComponent);

    this.displayUnmatched =
      this.isFilterUnmatched(this.projectDeliverableTableComponent) &&
      this.isFilterUnmatched(this.daDeliverableTableComponent);
    this.UpdateDisplayViewSelected();
  }

  displayViewSelectedChange() {
    this.displayViewMatSelect.close();

    // check if select matched option
    if (
      !this.displayMatched &&
      this.displayViewSelected.includes(this.MATCHED)
    ) {
      this.displayMatched = true;
      this.displayUnmatched = false;
      this.applyMapFilterForTables({
        map: { type: 'greaterThan', filter: '0' },
      });
      this.UpdateDisplayViewSelected();
      return;
    }

    // check if unselect matched option
    if (
      this.displayMatched &&
      !this.displayViewSelected.includes(this.MATCHED)
    ) {
      this.displayMatched = false;
      this.cleanMapFilterForTables();
      this.UpdateDisplayViewSelected();
      return;
    }

    // check if selected unmatched option
    if (
      !this.displayUnmatched &&
      this.displayViewSelected.includes(this.UNMATCHED)
    ) {
      this.displayUnmatched = true;
      this.displayMatched = false;
      this.applyMapFilterForTables({
        map: { type: 'equals', filter: '0' },
      });
      this.UpdateDisplayViewSelected();
      return;
    }

    // check if unselect unmatched option
    if (
      this.displayUnmatched &&
      !this.displayViewSelected.includes(this.UNMATCHED)
    ) {
      this.displayUnmatched = false;
      this.cleanMapFilterForTables();
      this.UpdateDisplayViewSelected();
    }
  }

  private UpdateDisplayViewSelected() {
    this.displayViewSelected = [];
    if (this.displayMatched) {
      this.displayViewSelected.push(this.MATCHED);
    }
    if (this.displayUnmatched) {
      this.displayViewSelected.push(this.UNMATCHED);
    }
  }

  private applyMapFilterForTables(mapFilter: any) {
    this.daDeliverableTableComponent?.agGrid.api.setFilterModel(mapFilter);
    this.projectDeliverableTableComponent?.agGrid.api.setFilterModel(mapFilter);
  }

  private cleanMapFilterForTables() {
    this.daDeliverableTableComponent?.agGrid.api.destroyFilter('map');
    this.projectDeliverableTableComponent?.agGrid.api.destroyFilter('map');
  }
}
