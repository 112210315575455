<ng-container *ngIf="rowData?.length">
  <div class="filter-container">
    <app-grid-quick-filter
      [configStoreTableName]="configStoreTableName"
      [configStoreRouteIdName]="configStoreRouteIdName"
      (filterChanged)="onQuickFilterChanged($event)"
      (filterTagsChanged)="onQuickFilterTagsChanged($event)"
      [tagsMode]="true"
    >
    </app-grid-quick-filter>
  </div>
  <app-ag-grid-options
    [showQuickFilter]="false"
    [configStoreTableName]="configStoreTableName"
    [configStoreRouteIdName]="configStoreRouteIdName"
  ></app-ag-grid-options>
  <ag-grid-angular
    columnHoverHighlight
    rowHoverHighlight
    class="ag-theme-adap ag-sticky"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [frameworkComponents]="frameworkComponents"
    domLayout="normal"
    rowClass="ag-no-border"
    configStore
    [configStoreTableName]="configStoreTableName"
    [configStoreRouteIdName]="configStoreRouteIdName"
    [customIsExternalFilter]="isExternalFilterPresent.bind(this)"
    [customDoesExternalFilterPass]="doesExternalFilterPass.bind(this)"
    (filterChanged)="onFilterChanged()"
    columnSizeStrategy="sizeColumnsToFit"
    rowSelection="multiple"
    (selectionChanged)="onSelectionChanged($event)"
    suppressScrollOnNewData
  >
  </ag-grid-angular>
</ng-container>
