import { Component, OnInit } from '@angular/core';
import { ChipInfo, getClassName, getTooltipText } from '../../utils/chip-utils';

@Component({
  selector: 'app-ag-grid-chip-renderer',
  templateUrl: './ag-grid-chip-renderer.component.html',
  styleUrls: ['./ag-grid-chip-renderer.component.scss'],
})
export class AgGridChipRendererComponent implements OnInit {
  value!: string;
  chipMap!: Map<string, ChipInfo>;
  className: string = '';
  tooltipText: string = '';

  agInit(params: { value: string; chipMap: Map<string, ChipInfo> }): void {
    this.value = params.value;
    this.chipMap = params.chipMap;
  }

  ngOnInit(): void {
    this.className = getClassName(this.value);
    this.tooltipText = getTooltipText(this.value);
  }
}
