import { Component, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiceNowForm } from '../../models/autogenerated';

@Component({
  selector: 'app-feedback-snack-bar',
  templateUrl: './feedback-snack-bar.component.html',
  styleUrls: ['./feedback-snack-bar.component.scss'],
})
export class FeedbackSnackBarComponent {
  constructor(private snackBar: MatSnackBar) {}
  private url = ServiceNowForm.Url;

  dismiss() {
    this.snackBar.dismiss();
  }
  openFeedback() {
    window.open(this.url, '_blank');
  }
}
