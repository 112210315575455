import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormGroupState } from 'ngrx-forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { BusinessUnitViewModel } from 'src/app/shared/models/autogenerated';
import { selectProjectThemesList } from 'src/app/shared/store/consts.selectors';
import { CustomNgrxValueConverters } from 'src/app/shared/utils/date-utils';
import { TOTAL_COST_BUFFER } from '../../../da-engineer.constants';
import { DESCRIPTION_MAX_LENGTH } from '../../../workplan/workplan.constants';
import {
  CURRENT_PHASE_ID,
  LEAD_BY_TOOLTIP,
  NEW_WORK_PLAN_CONTACTS_LINK,
  USER_ANONYMITY_TOOLTIP,
} from '../../new-workplan-flow.constants';
import { NewWorkplanForm } from '../../store/new-project.reducer';
import {
  selectBusinessUnits,
  selectLeadByList,
  selectNewProjectAvailablePhases,
  selectNewProjectCreatedProject,
  selectNewWorkplanForm,
} from '../../store/new-project.selectors';

@Component({
  selector: 'app-new-project-overview',
  templateUrl: './new-project-overview.component.html',
  styleUrls: ['./new-project-overview.component.scss'],
})
export class NewProjectOverviewComponent {
  newWorkplanContactsLink = NEW_WORK_PLAN_CONTACTS_LINK;
  currentPhaseId = CURRENT_PHASE_ID;
  leadByTooltip = LEAD_BY_TOOLTIP;
  userAnonymityTooltip = USER_ANONYMITY_TOOLTIP;
  descriptionMaxLength = DESCRIPTION_MAX_LENGTH;
  totalCostBuffer = TOTAL_COST_BUFFER;

  formState$: Observable<FormGroupState<NewWorkplanForm>>;
  availablePhases$: Observable<string[]>;
  projectThemes$: Observable<string[]>;
  businessUnits$: Observable<BusinessUnitViewModel[] | null>;
  leadByList$: Observable<string[]>;
  workplanCreatedProject$ = this.store.select(selectNewProjectCreatedProject);
  workplanWasCreated$ = this.workplanCreatedProject$.pipe(
    map((workplan) => workplan != null)
  );

  constructor(private store: Store<AppState>) {
    this.formState$ = this.store.select(selectNewWorkplanForm);
    this.availablePhases$ = this.store.select(selectNewProjectAvailablePhases);
    this.projectThemes$ = this.store.select(selectProjectThemesList);
    this.businessUnits$ = this.store.select(selectBusinessUnits);
    this.leadByList$ = this.store.select(selectLeadByList);
  }

  dateOnlyToISOString = CustomNgrxValueConverters.dateOnlyToISOString;
}
