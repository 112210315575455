import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith, take, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { selectCurrentPhase } from 'src/app/roles/da-engineer/project-dashboard/store/project.selectors';
import { setLastVisitedPage } from 'src/app/roles/_shared-by-role/workplan/store/workplan.actions';
import { QuickFilterModes } from 'src/app/shared/ag-grid-config/ag-grid-config.constants';
import { selectFilterMode } from 'src/app/shared/ag-grid-config/store/ag-grid-config/ag-grid-config.selectors';
import {
  DADeliverableWithMappingsViewModel,
  ProjectDeliverableWithMappingsViewModel,
} from 'src/app/shared/models/autogenerated';
import { DeliverableManagerDADeliverableTableComponent } from '../../components/deliverable-manager-da-deliverable-table/deliverable-manager-da-deliverable-table.component';
import { DeliverableManagerMatchedFilterComponent } from '../../components/deliverable-manager-matched-filter/deliverable-manager-matched-filter.component';
import { DeliverableManagerProjectDeliverableTableComponent } from '../../components/deliverable-manager-project-deliverable-table/deliverable-manager-project-deliverable-table.component';
import {
  DELIVERABLES_CLASS_ALL_TEXT,
  DELIVERABLE_MANAGER_TABLE_DA_CONFIG_STORE,
  DELIVERABLE_MANAGER_TABLE_PROJECT_CONFIG_STORE,
  DELIVERABLE_MANAGER_TABLE_ROUTE_ID,
  DeliverableTypes,
  ScreeningRoutes,
} from '../../screening.constants';
import {
  createProjectDaDeliverableMapping,
  loadDeliverablesWithMappingsForPhase,
  loadProjectDeliverablesWithMappingsForPhase,
} from '../../store/screening.actions';
import {
  selectDeliverablesWithMappingsForPhase,
  selectProjectDeliverablesWithMappingsForPhase,
  selectUnmatchedDaAndProjectDeliverablesByName,
  selectDaAndProjectDeliverablesWithNoMappings,
} from '../../store/screening.selectors';
import { ImportExcelDialogComponent } from '../import-excel-dialog/import-excel-dialog.component';
import { OneOnOneMatchComponent } from '../one-on-one-match/one-on-one-match.component';
import { AutomatchComponent } from '../automatch/automatch.component';
import { MatchingManagerAbstract } from '../../components/matching-manager.abstract';
import { openSpreadsheetDialog } from '../../screening.functions';

@Component({
  selector: 'app-deliverable-manager',
  templateUrl: './deliverable-manager.component.html',
  styleUrls: ['./deliverable-manager.component.scss'],
})
export class DeliverableManagerComponent extends MatchingManagerAbstract<
  DeliverableManagerDADeliverableTableComponent,
  DeliverableManagerProjectDeliverableTableComponent
> {
  public static SHOWING_ONLY_MAPPED_DELIVERABLES =
    '(showing only mapped deliverables)';
  @ViewChild(DeliverableManagerProjectDeliverableTableComponent)
  projectDeliverableTableComponent!: DeliverableManagerProjectDeliverableTableComponent;
  @ViewChild(DeliverableManagerDADeliverableTableComponent)
  firstTableComponent!: DeliverableManagerDADeliverableTableComponent;

  @ViewChild(DeliverableManagerMatchedFilterComponent)
  matchedFilter!: DeliverableManagerMatchedFilterComponent;

  daDeliverableMatchRate: number = 0;

  DELIVERABLES_CLASS_ALL_TEXT = DELIVERABLES_CLASS_ALL_TEXT;
  phase$ = this.store.select(selectCurrentPhase);

  projectDeliverables$ = this.store.select(
    selectProjectDeliverablesWithMappingsForPhase
  );
  deliverables$ = this.store
    .select(selectDeliverablesWithMappingsForPhase)
    .pipe(
      tap((deliverables) => this.calculateDaDeliverablesMatchRate(deliverables))
    );

  projectDeliverablesSelected: ProjectDeliverableWithMappingsViewModel[] = [];
  deliverablesSelected: DADeliverableWithMappingsViewModel[] = [];

  isQuickFilterLinkAvailable$!: Observable<boolean>;

  private importExcelDialogRef!: MatDialogRef<ImportExcelDialogComponent, any>;
  private oneOnOneMatchDialogRef!: MatDialogRef<OneOnOneMatchComponent, any>;
  private autoMatchDialogRef!: MatDialogRef<AutomatchComponent, any>;

  constructor(
    store: Store<AppState>,
    cdRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    super(store, cdRef);
    this.store.dispatch(loadDeliverablesWithMappingsForPhase());
    this.store.dispatch(loadProjectDeliverablesWithMappingsForPhase());
    this.store.dispatch(setLastVisitedPage());
    this.isQuickFilterLinkAvailable$ = combineLatest([
      this.store.select(
        selectFilterMode(
          DELIVERABLE_MANAGER_TABLE_DA_CONFIG_STORE,
          DELIVERABLE_MANAGER_TABLE_ROUTE_ID
        )
      ),
      this.store.select(
        selectFilterMode(
          DELIVERABLE_MANAGER_TABLE_PROJECT_CONFIG_STORE,
          DELIVERABLE_MANAGER_TABLE_ROUTE_ID
        )
      ),
    ]).pipe(
      map(([project, da]) => project === da),
      tap((x) => {
        if (x) {
          this.areQuickFiltersLinked = true;
          this.toggleLinkQuickFilters();
        }
      }),
      startWith(false)
    );
  }

  matchDeliverables() {
    this.store.dispatch(
      createProjectDaDeliverableMapping({
        projectDeliverableIdList: this.projectDeliverablesSelected.map(
          (x) => x.id
        ),
        deliverableIdList: this.deliverablesSelected.map((x) => x.id),
      })
    );
    this.deselectAll();
  }

  onProjectDeliverablesSelectionChanged(
    projectDeliverablesSelected: ProjectDeliverableWithMappingsViewModel[]
  ) {
    this.projectDeliverablesSelected = projectDeliverablesSelected;
  }

  onDeliverablesSelectionChanged(
    deliverablesSelected: DADeliverableWithMappingsViewModel[]
  ) {
    this.deliverablesSelected = deliverablesSelected;
  }

  toggleLinkQuickFilters() {
    this.areQuickFiltersLinked = !this.areQuickFiltersLinked;
    this.linkSearchFieldsSubscription?.unsubscribe();

    if (this.areQuickFiltersLinked) {
      this.linkSearchFieldsSubscription = new Subscription();
      this.store
        .select(
          selectFilterMode(
            DELIVERABLE_MANAGER_TABLE_PROJECT_CONFIG_STORE,
            DELIVERABLE_MANAGER_TABLE_ROUTE_ID
          )
        )
        .pipe(take(1))
        .subscribe((mode) => {
          if (mode === QuickFilterModes.text) {
            this.setLinkedTextQuickFilters();
          } else {
            this.setLinkedTagsQuickFilters();
          }
        });
    }
  }

  calculateDaDeliverablesMatchRate(
    daDeliverables: DADeliverableWithMappingsViewModel[] | null
  ) {
    if (daDeliverables == null || daDeliverables.length === 0) {
      this.daDeliverableMatchRate = 0;
      return;
    }

    const totalItems = daDeliverables.length;
    const totalDeliverablesWithMappings = daDeliverables.filter(
      (x) => x.projectDeliverableIdList?.length > 0
    ).length;

    this.daDeliverableMatchRate = Math.floor(
      (totalDeliverablesWithMappings / totalItems) * 100
    );
  }

  async importExcel() {
    this.importExcelDialogRef = openSpreadsheetDialog(
      this.dialog,
      DeliverableTypes.DA_DELIVERABLES
    );
    this.importExcelDialogRef.afterClosed().subscribe(() => {
      this.importExcelDialogRef.componentInstance.closeWindow();
    });
  }

  openOneOnOneMatchDialog() {
    this.oneOnOneMatchDialogRef = this.dialog.open(OneOnOneMatchComponent, {
      panelClass: 'one-on-one-match-dialog',
      closeOnNavigation: false,
      disableClose: true,
      maxWidth: 'unset',
      data: {
        oneOnOneMatches$: this.store
          .select(selectUnmatchedDaAndProjectDeliverablesByName)
          .pipe(take(1)),
      },
    });

    this.oneOnOneMatchDialogRef.afterClosed().subscribe((result) => {
      this.oneOnOneMatchDialogRef.componentInstance.closeWindow();
    });
  }

  openAutoMatchingDialog() {
    this.autoMatchDialogRef = this.dialog.open(AutomatchComponent, {
      panelClass: 'automatch-dialog',
      closeOnNavigation: false,
      disableClose: true,
      maxWidth: 'unset',
      data: {
        allDeliverables$: this.store
          .select(selectDaAndProjectDeliverablesWithNoMappings)
          .pipe(take(1)),
      },
    });

    this.autoMatchDialogRef.afterClosed().subscribe((result) => {
      this.autoMatchDialogRef.componentInstance.closeWindow();
    });
  }

  navigateToDeliverables() {
    this.router.navigate(['../..', ScreeningRoutes.DELIVERABLES_ROUTING_PATH], {
      relativeTo: this.route,
    });
  }

  onFilterChanged() {
    this.matchedFilter.filterChanged();
  }
}
