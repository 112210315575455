import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AgGridCheckboxRendererComponent } from 'src/app/shared/components/ag-grid-checkbox-renderer/ag-grid-checkbox-renderer.component';
import { AdapCellClassRules } from 'src/app/shared/utils/ag-grid-utils';

@Component({
  selector: 'app-ipims-common-table',
  templateUrl: './ipims-common-table.component.html',
  styleUrls: ['./ipims-common-table.component.scss'],
})
export class IPIMSCommonTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(AgGridAngular) table!: AgGridAngular;
  @Input() configStoreTableName: string = '';
  @Input() configStoreRouteIdName: string = '';
  @Input() columnDefs: ColDef[] = [];
  @Input() rows: Array<any> = [];
  @Output() rowsRendered: EventEmitter<void> = new EventEmitter();

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    cellClassRules: AdapCellClassRules,
  };

  frameworkComponents = {
    agGridCheckbox: AgGridCheckboxRendererComponent,
  };

  private subscriptions: Subscription = new Subscription();

  ngAfterViewInit(): void {
    // we need to increase the buffer, otherwise for some reason,
    // the ag-grid checkbox shows as unselected when
    // the user scrolls and is rendered again
    // https://www.ag-grid.com/javascript-data-grid/dom-virtualisation/
    this.table.gridOptions.rowBuffer = 200;
    this.table.gridReady.pipe(take(1)).subscribe(() => {
      this.subscriptions.add(
        // delay needed to let the checkbox rows render first
        this.table?.rowDataChanged.subscribe((x) => {
          this.rowsRendered.emit();
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
