import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import {
  DADeliverableWithMappingsViewModel,
  IDADeliverableWithMappingsViewModel,
  IProjectDeliverableBaseViewModel,
  IProjectDeliverableWithMappingsViewModel,
  ProjectDeliverableWithMappingsViewModel,
  IDADeliverableBaseViewModel,
} from 'src/app/shared/models/autogenerated';
import { ColDef, SelectionChangedEvent } from 'ag-grid-community';
import { ExportableColDefWithTitle } from 'src/app/shared/ag-grid-config/store/ag-grid-config/ag-grid-config.reducer';
import { createProjectDaDeliverableMapping } from '../../store/screening.actions';
import { AdapCellClassRules } from 'src/app/shared/utils/ag-grid-utils';
import { nameof } from 'src/app/shared/utils/nameof';
import { ScreeningState } from '../../store/screening.reducer';
import { DeliverableManagerGridCellActionsModel } from 'src/app/shared/models/deliverable-manager-grid-cell-actions.model';
import { ProjectDeliverableAutomatchViewModel } from '../../models/project-deliverable-automatch.model';
import { ToastMessagesService } from 'src/app/shared/services/toast-messages.service';
import { StringMatchingService } from 'src/app/shared/services/string-matching.service';

type SimilarityMap = {
  [key: string]: {
    daDeliverable: DADeliverableWithMappingsViewModel;
    projectDeliverable: ProjectDeliverableAutomatchViewModel;
  }[];
};

@Component({
  selector: 'app-automatch',
  templateUrl: './automatch.component.html',
  styleUrls: ['./automatch.component.scss'],
})
export class AutomatchComponent implements OnInit {
  allDeliverables$: Observable<
    [
      DADeliverableWithMappingsViewModel[],
      ProjectDeliverableWithMappingsViewModel[]
    ]
  >;
  daDeliverables: DADeliverableWithMappingsViewModel[] = [];
  projectDeliverables: ProjectDeliverableWithMappingsViewModel[] = [];
  similarityMap: SimilarityMap = {};

  projectDeliverablesSelected: ProjectDeliverableWithMappingsViewModel[] = [];
  private gridApiProjectDeliverables: any;
  currentPage = 1;
  currentPageDADeliverable: DADeliverableWithMappingsViewModel | null = null;
  currentPageProjectDeliverable: ProjectDeliverableAutomatchViewModel[] = [];

  isMatchButtonDisabled: boolean = true;
  public showDeliverables: boolean = true;

  daDeliverableColumnDefs: ExportableColDefWithTitle[] = [
    {
      field: nameof<IDADeliverableWithMappingsViewModel>('title'),
      headerName: 'da title',
      resizable: true,
      tooltipField: nameof<IDADeliverableWithMappingsViewModel>('title'),
      pinned: 'left',
      width: 450,
    },
    {
      field: nameof<IDADeliverableWithMappingsViewModel>(
        'deliverableDiscipline'
      ),
      headerName: 'discipline',
      resizable: true,
      tooltipField: nameof<IDADeliverableBaseViewModel>(
        'deliverableDiscipline'
      ),
      flex: 1,
    },
    {
      field: nameof<IDADeliverableWithMappingsViewModel>('docType'),
      headerName: 'type',
      resizable: true,
      tooltipField: nameof<IDADeliverableWithMappingsViewModel>('docType'),
      flex: 1,
    },
    {
      headerName: 'Map',
      colId: 'map',
      minWidth: 90,
      maxWidth: 90,
      pinned: 'right',
      filter: 'number',
      valueGetter: (params) =>
        params.data.projectDeliverableIdList?.length ?? 0,
      type: 'centerAligned',
      cellRenderer: 'deliverableManagerMappingRenderer',
      cellRendererParams: {} as DeliverableManagerGridCellActionsModel,
      flex: 1,
    },
  ];

  projectDeliverableColumnDefs: ExportableColDefWithTitle[] = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      resizable: false,
      minWidth: 56,
      maxWidth: 56,
      type: 'centerAligned',
      pinned: 'left',
      ignoreExport: true,
      lockVisible: true,
      flex: 1,
    },
    {
      field: nameof<IProjectDeliverableWithMappingsViewModel>('name'),
      headerName: 'da title',
      resizable: true,
      tooltipField: nameof<IProjectDeliverableWithMappingsViewModel>('name'),
      pinned: 'left',
      flex: 3,
    },
    {
      headerName: 'recommendation',
      field: 'recommendation',
      resizable: true,
      pinned: 'left',
      flex: 1,
    },
    {
      field: nameof<IProjectDeliverableWithMappingsViewModel>('discipline'),
      headerName: 'discipline',
      resizable: true,
      tooltipField: nameof<IProjectDeliverableBaseViewModel>('discipline'),
      flex: 1,
    },
    {
      field: nameof<IProjectDeliverableWithMappingsViewModel>('type'),
      headerName: 'type',
      resizable: true,
      tooltipField: nameof<IProjectDeliverableWithMappingsViewModel>('type'),
      flex: 1,
    },
  ];

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    filterParams: { newRowsAction: 'keep' },
    resizable: false,
    cellClassRules: AdapCellClassRules,
  };

  constructor(
    private store: Store<ScreeningState>,
    public dialogRef: MatDialogRef<AutomatchComponent>,
    private toastMessagesService: ToastMessagesService,
    private stringMatchingService: StringMatchingService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      allDeliverables$: Observable<
        [
          DADeliverableWithMappingsViewModel[],
          ProjectDeliverableWithMappingsViewModel[]
        ]
      >;
    }
  ) {
    this.allDeliverables$ = data.allDeliverables$;
  }

  ngOnInit(): void {
    this.allDeliverables$.subscribe((data) => {
      if (data === null) {
        this.daDeliverables = [];
        this.projectDeliverables = [];
        this.showDeliverables = false;
        this.updateCurrentPageInfo();
        return;
      }

      const [allDaDeliverables, allProjectDeliverables] = data;

      this.daDeliverables = allDaDeliverables.filter(
        (deliverable) => !!deliverable.title
      );
      this.projectDeliverables = allProjectDeliverables;
      this.similarityMap =
        this.stringMatchingService.calculateStringMatchingScores(
          this.daDeliverables,
          this.projectDeliverables
        );
      this.daDeliverables.sort(
        (a, b) =>
          this.similarityMap[a.title!][0]?.projectDeliverable.score -
          this.similarityMap[b.title!][0]?.projectDeliverable.score
      );
      this.showDeliverables = true;
      this.updateCurrentPageInfo();
    });
  }

  matchDeliverables() {
    this.store.dispatch(
      createProjectDaDeliverableMapping({
        projectDeliverableIdList: this.projectDeliverablesSelected.map(
          (x) => x.id
        ),
        deliverableIdList: [this.currentPageDADeliverable!.id],
      })
    );
    this.toastMessagesService.displaySuccess(
      'Deliverables have been matched successfully'
    );
    this.nextPage();
  }

  onProjectDeliverablesSelectionChanged(event: SelectionChangedEvent) {
    var projectDeliverablesSelected = event.api!.getSelectedRows();
    this.projectDeliverablesSelected = projectDeliverablesSelected;
    this.isMatchButtonDisabled = this.projectDeliverablesSelected.length === 0;
  }

  private updateCurrentPageInfo() {
    this.currentPageDADeliverable = this.daDeliverables[this.currentPage - 1];
    if (this.currentPageDADeliverable && this.currentPageDADeliverable.title) {
      const data = this.similarityMap[this.currentPageDADeliverable.title];
      this.currentPageProjectDeliverable = data.map(
        (item: any) => item.projectDeliverable
      );
    }
  }

  navigateToPage(pageNumber: number): void {
    pageNumber = Math.floor(pageNumber);
    if (pageNumber > 0 && pageNumber <= this.daDeliverables.length) {
      this.currentPage = pageNumber;
      this.updateCurrentPageInfo();
    } else {
      this.currentPage = 1;
    }
  }

  nextPage() {
    this.currentPage = Math.min(++this.currentPage, this.daDeliverables.length);
    this.updateCurrentPageInfo();
    this.clearSelection();
  }

  previousPage() {
    this.currentPage = Math.max(--this.currentPage, 1);
    this.updateCurrentPageInfo();
    this.clearSelection();
  }

  onGridReady(params: any) {
    this.gridApiProjectDeliverables = params.api;
  }

  clearSelection() {
    this.gridApiProjectDeliverables.deselectAll();
  }

  closeWindow(): void {
    this.dialogRef.close();
  }
}
