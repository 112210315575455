import { Store } from '@ngrx/store';
import { FileParameter } from '../../../shared/models/autogenerated';
import {
  checkForDADeliverablesImportDuplicates,
  importDADeliverables,
} from './store/screening.actions';
import { ImportExcelDialogComponent } from './pages/import-excel-dialog/import-excel-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeliverableTypes } from './screening.constants';
import {
  checkForProjectDeliverablesImportDuplicates,
  importProjectDeliverables,
} from '../project-deliverables/store/project-deliverables.actions';

export function openSpreadsheetDialog(
  dialog: MatDialog,
  deliverableType: DeliverableTypes
) {
  return dialog.open(ImportExcelDialogComponent, {
    panelClass: 'import-excel-dialog-config',
    closeOnNavigation: false,
    disableClose: true,
    maxWidth: 'unset',
    data: { deliverableType: deliverableType },
  });
}

export async function importExcelFile(
  fileInput: HTMLInputElement | null,
  store: Store,
  onlyAdd: boolean,
  insertMissing: boolean,
  removeDeleted: boolean,
  updateExisting: boolean,
  deliverableType: DeliverableTypes
) {
  if (
    fileInput == null ||
    fileInput.files == null ||
    fileInput.files.length === 0
  ) {
    return;
  }

  const fileToUpload = fileInput.files[0];
  const fileParameter: FileParameter = {
    data: fileToUpload,
    fileName: fileToUpload.name,
  };

  if (deliverableType == DeliverableTypes.DA_DELIVERABLES) {
    if (onlyAdd) {
      store.dispatch(
        checkForDADeliverablesImportDuplicates({
          file: fileParameter,
        })
      );
    } else {
      store.dispatch(
        importDADeliverables({
          file: fileParameter,
          onlyAdd,
          insertMissing,
          removeDeleted,
          updateExisting,
        })
      );
    }
  } else if (deliverableType == DeliverableTypes.PROJECT_DELIVERABLES) {
    if (onlyAdd) {
      store.dispatch(
        checkForProjectDeliverablesImportDuplicates({
          file: fileParameter,
        })
      );
    } else {
      store.dispatch(
        importProjectDeliverables({
          file: fileParameter,
          onlyAdd,
          insertMissing,
          removeDeleted,
          updateExisting,
        })
      );
    }
  }

  // reset file input to enable re-upload
  fileInput.value = '';
}
