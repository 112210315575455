import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CheckWithWorkloadEstimatesViewModel,
  DADeliverableWithWorkloadEstimatesViewModel,
} from 'src/app/shared/models/autogenerated';
import { selectRouteNestedParam } from 'src/app/shared/store/shared.selectors';
import {
  DADeliverableWithMappingsViewModel,
  ProjectDeliverableWithMappingsViewModel,
} from '../../../../shared/models/autogenerated';
import {
  DaAndProjectDeliverablesMatched,
  DADeliverableWithProjectDeliverablesViewModel,
  ProjectDeliverableWithDADeliverablesViewModel,
} from '../models/deliverable-manager-overview.model';
import {
  AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE,
  AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE_ID,
  NUMBER_OF_CHECKS_PER_DISCIPLINE,
  NUMBER_OF_CHECKS_PER_DISCIPLINE_ID,
  NUMBER_OF_DELIVERABLES_PER_DISCIPLINE,
  NUMBER_OF_DELIVERABLES_PER_DISCIPLINE_ID,
  TOTAL,
  TOTAL_ID,
} from '../screening.constants';
import { DeliverableManagerState, ScreeningState } from './screening.reducer';

export const selectScreeningFeature =
  createFeatureSelector<ScreeningState>('screening');

export const selectAllDeliverableTemplates = createSelector(
  selectScreeningFeature,
  (state) => state.allDeliverableTemplates
);

export const selectDADeliverableForm = createSelector(
  selectScreeningFeature,
  (state) => state.daDeliverableForm
);

export const selectDeliverablesForPhase = createSelector(
  selectScreeningFeature,
  (state) => state.deliverablesForPhase
);

export const selectDeliverablesWithMappingsForPhase = createSelector(
  selectScreeningFeature,
  (state) => state.deliverablesWithMappingsForPhase
);

export const selectStatistics = createSelector(
  selectScreeningFeature,
  (state) => {
    const totalHours = state.estimatesStatistics
      .map((e) => e.hours)
      .reduce((acc, curr) => acc + curr, 0);
    const totalCount = (state.deliverablesWithEstimates ?? []).filter(
      (d) => d.workloadEstimates.filter((e) => e.hours > 0).length > 0
    ).length;
    const totalHoursRounded = totalHours.toFixed(1);
    const average = Math.round(totalHours / totalCount);
    return [
      {
        title: TOTAL,
        id: TOTAL_ID,
        workloadEstimates: state.estimatesStatistics,
        totalHours: totalHoursRounded,
      },
      {
        title: NUMBER_OF_DELIVERABLES_PER_DISCIPLINE,
        id: NUMBER_OF_DELIVERABLES_PER_DISCIPLINE_ID,
        workloadEstimates: state.estimatesStatistics.map((x) => ({
          ...x,
          hours: x.numberOfDeliverables,
        })),
        totalHours: totalCount,
      },
      {
        title: AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE,
        id: AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE_ID,
        workloadEstimates: state.estimatesStatistics.map((x) => ({
          ...x,
          hours: x.averageNumberOfHours,
        })),
        totalHours: average,
      },
    ] as DADeliverableWithWorkloadEstimatesViewModel[];
  }
);

export const selectStatisticsAdditionalCheck = createSelector(
  selectScreeningFeature,
  (state) => {
    const totalHours = state.estimatesStatisticsAdditionalChecks
      .map((e) => e.hours)
      .reduce((acc, curr) => acc + curr, 0);
    const totalCount = (state.checksWithEstimates ?? []).filter(
      (d) =>
        d.workloadEstimateAdditionalChecks.filter((e) => e.hours > 0).length > 0
    ).length;
    const totalHoursRounded = totalHours.toFixed(1);
    const average = Math.round(totalHours / totalCount);
    return [
      {
        type: TOTAL,
        id: TOTAL_ID,
        workloadEstimateAdditionalChecks:
          state.estimatesStatisticsAdditionalChecks,
        totalHours: totalHoursRounded,
      },
      {
        type: NUMBER_OF_CHECKS_PER_DISCIPLINE,
        id: NUMBER_OF_CHECKS_PER_DISCIPLINE_ID,
        workloadEstimateAdditionalChecks:
          state.estimatesStatisticsAdditionalChecks.map((x) => ({
            ...x,
            hours: x.numberOfChecks,
          })),
        totalHours: totalCount,
      },
      {
        type: AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE,
        id: AVERAGE_NUMBER_OF_HOURS_PER_DISCIPLINE_ID,
        workloadEstimateAdditionalChecks:
          state.estimatesStatisticsAdditionalChecks.map((x) => ({
            ...x,
            hours: x.averageNumberOfHours,
          })),
        totalHours: average,
      },
    ] as CheckWithWorkloadEstimatesViewModel[];
  }
);

export const selectAllDeliverablesWithEstimates = createSelector(
  selectScreeningFeature,
  (state) => state.deliverablesWithEstimates
);

export const selectAllChecksWithEstimates = createSelector(
  selectScreeningFeature,
  (state) => state.checksWithEstimates
);

export const selectCarryOverObservationsWithEstimatesAndSmeNames =
  createSelector(
    selectScreeningFeature,
    (state) => state.carryOverObservationsWithEstimatesAndSmeNames
  );

export const selectMasterList = createSelector(
  selectScreeningFeature,
  (state) => state.masterList
);

export const selectProjectDeliverables = createSelector(
  selectScreeningFeature,
  (state) => state.projectDeliverables
);

export const selectProjectDeliverableEditForm = createSelector(
  selectScreeningFeature,
  (state) => state.projectDeliverableForm
);

export const selectProjectDeliverableEditFormIsValid = createSelector(
  selectScreeningFeature,
  (state) => state.projectDeliverableForm.isValid
);

export const selectProjectDeliverablesWithMappingsForPhase = createSelector(
  selectScreeningFeature,
  (state) => state.projectDeliverablesWithMappingsForPhase
);

export const selectProjectDeliverablesWithCheckMappingsForPhase =
  createSelector(
    selectScreeningFeature,
    (state) => state.projectDeliverablesWithCheckMappingsForPhase
  );

export const selectCheckWithMappingsForPhase = createSelector(
  selectScreeningFeature,
  (state) => state.checksWithMappingsForPhase
);

export const selectWorkplanEstimatesPlan = createSelector(
  selectScreeningFeature,
  (state) => state.workplanEstimatesPlan
);

export const selectDeliverableManagerFeature =
  createFeatureSelector<DeliverableManagerState>('deliverableManagerConfig');

export const selectDeliverableManagerQuickFilter = createSelector(
  selectDeliverableManagerFeature,
  selectRouteNestedParam('phaseId'),
  (state, phaseId) => state.deliverableManagerConfig?.[phaseId]?.quickFilter
);

export const selectDeliverableManagerOverview = createSelector(
  selectScreeningFeature,
  (state) => state.deliverableManagerOverview
);

export const selectUnmatchedCarryOverProjectDeliverables = createSelector(
  selectScreeningFeature,
  (state) => state.unmatchedCarryOverProjectDeliverables
);

export const selectDeliverableManagerOverviewLeadByDa = createSelector(
  selectDeliverableManagerOverview,
  selectDeliverableManagerQuickFilter,
  (deliverableManagerOverview, quickFilter) => {
    const result = deliverableManagerOverview?.reduce((acc, curr) => {
      const alreadyGrouped = acc.find((d) => d.id == curr.deliverableId);
      if (alreadyGrouped != null) {
        alreadyGrouped.projectDeliverables.push(curr.projectDeliverable);
      } else {
        acc.push({
          ...curr.deliverable,
          projectDeliverables: [curr.projectDeliverable],
        } as DADeliverableWithProjectDeliverablesViewModel);
      }
      return acc;
    }, [] as DADeliverableWithProjectDeliverablesViewModel[]);
    const quickFilterForComparison = quickFilter?.toLowerCase() ?? '';

    return result?.filter(
      (item) =>
        item.title?.toLowerCase().includes(quickFilterForComparison) ||
        item.deliverableDiscipline
          ?.toLowerCase()
          .includes(quickFilterForComparison) ||
        item.projectDeliverables?.filter((project) =>
          project.name.toLowerCase().includes(quickFilterForComparison)
        ).length > 0
    );
  }
);

export const selectDeliverableManagerOverviewLeadByProject = createSelector(
  selectDeliverableManagerOverview,
  selectDeliverableManagerQuickFilter,
  (deliverableManagerOverview, quickFilter) => {
    const result = deliverableManagerOverview?.reduce((acc, curr) => {
      const alreadyGrouped = acc.find((d) => d.id == curr.projectDeliverableId);
      if (alreadyGrouped != null) {
        alreadyGrouped.daDeliverables.push(curr.deliverable);
      } else {
        acc.push({
          ...curr.projectDeliverable,
          daDeliverables: [curr.deliverable],
        } as ProjectDeliverableWithDADeliverablesViewModel);
      }
      return acc;
    }, [] as ProjectDeliverableWithDADeliverablesViewModel[]);
    const quickFilterForComparison = quickFilter?.toLowerCase() ?? '';

    return result?.filter(
      (item) =>
        item.name?.toLowerCase().includes(quickFilterForComparison) ||
        item.discipline?.toLowerCase().includes(quickFilterForComparison) ||
        item.daDeliverables?.filter((da) =>
          da.title?.toLowerCase().includes(quickFilterForComparison)
        ).length > 0
    );
  }
);

export const selectDeliverableManagerOverviewPageView = createSelector(
  selectDeliverableManagerFeature,
  selectRouteNestedParam('phaseId'),
  (state, phaseId) =>
    state.deliverableManagerConfig?.[phaseId]?.overviewPageView ?? 'cards'
);

export const selectUnmatchedDaAndProjectDeliverablesByName = createSelector(
  selectDeliverablesWithMappingsForPhase,
  selectProjectDeliverablesWithMappingsForPhase,
  (daDeliverables, projectDeliverables) => {
    const unmatchedDaDeliverables: DADeliverableWithMappingsViewModel[] =
      daDeliverables?.filter((x) => x.projectDeliverableIdList.length === 0) ??
      [];
    const unmatchedProjectDeliverables: ProjectDeliverableWithMappingsViewModel[] =
      projectDeliverables?.filter((x) => x.daDeliverableIdList.length === 0) ??
      [];

    // edge case, nothing to match if one of the filtered lists is empty
    if (
      unmatchedDaDeliverables.length === 0 ||
      unmatchedProjectDeliverables.length === 0
    ) {
      return null;
    }

    const oneOnOneMatches = unmatchedDaDeliverables.reduce(
      (
        prev: DaAndProjectDeliverablesMatched[],
        curr: DADeliverableWithMappingsViewModel
      ) => {
        const projectDeliverablesMatched = unmatchedProjectDeliverables.filter(
          (x) => x.name.toLowerCase() === curr.title?.toLowerCase()
        );

        for (const projectDeliverable of projectDeliverablesMatched) {
          prev = [
            ...prev,
            {
              projectDeliverable: projectDeliverable,
              daDeliverable: curr,
            } as DaAndProjectDeliverablesMatched,
          ];
        }
        return prev;
      },
      [] as DaAndProjectDeliverablesMatched[]
    );

    return oneOnOneMatches;
  }
);

export const selectDaAndProjectDeliverablesWithNoMappings = createSelector(
  selectDeliverablesWithMappingsForPhase,
  selectProjectDeliverablesWithMappingsForPhase,
  (daDeliverables, projectDeliverables) => {
    const allDaDeliverables: DADeliverableWithMappingsViewModel[] =
      daDeliverables?.filter((x) => x.projectDeliverableIdList.length === 0) ??
      [];
    const allProjectDeliverables: ProjectDeliverableWithMappingsViewModel[] =
      projectDeliverables?.filter((x) => x.daDeliverableIdList.length === 0) ??
      [];

    if (allDaDeliverables.length === 0 || allProjectDeliverables.length === 0) {
      return null;
    }

    return [allDaDeliverables, allProjectDeliverables];
  }
);

export const selectDeliverableDisciplinesValueForm = createSelector(
  selectScreeningFeature,
  (state) => state.deliverableDisciplinesValueForm
);

export const selectSMEDisciplineConfigCodes = createSelector(
  selectScreeningFeature,
  (state) => state.smeDisciplineConfigCodes
);
