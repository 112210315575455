<mat-form-field
  class="adap-select"
  appearance="outline"
  [class.mat-form-field-invalid]="isTouched && isInvalid"
>
  <mat-select
    (selectionChange)="onSelectionChanged($event.value)"
    (closed)="onClose()"
    (opened)="searchElement.nativeElement.focus()"
    [ngModel]="selectedUser?.profile?.id"
    [placeholder]="placeholder"
    [disabled]="isDisabled"
  >
    <mat-select-trigger class="selected-user">
      <div
        class="usr-img"
        *ngIf="selectedUser?.photo | async as photo; else profileIcon"
      >
        <img alt="selected user image" [src]="photo | SafeUrl" />
      </div>
      <div>
        <span class="display-name">{{
          selectedUser?.profile?.displayName
        }}</span>
        <span class="job-title">{{ selectedUser?.profile?.jobTitle }}</span>
      </div>
      <ng-template #profileIcon>
        <div class="default-icon selected-icon">
          <mat-icon svgIcon="cvx-person"></mat-icon>
        </div>
      </ng-template>
    </mat-select-trigger>
    <input
      [(ngModel)]="searchText"
      (keyup)="onKeyUp()"
      (keydown)="$event.stopPropagation()"
      placeholder="Search"
      class="adap-form-input"
      #search
    />
    <button class="adap-btn-blank clear-btn" (click)="clearSearch()">
      <mat-icon svgIcon="cvx-close"></mat-icon>
    </button>
    <mat-option class="empty-option" *ngIf="users.length < 1"></mat-option>
    <mat-option
      class="user-container"
      *ngFor="let user of users"
      [value]="user.profile?.id"
    >
      <div
        class="usr-img aligned"
        *ngIf="user.photo | async as photo; else usrIcon"
      >
        <img alt="user image" [src]="photo | SafeUrl" />
      </div>
      <ng-template #usrIcon>
        <div class="default-icon usr-icon">
          <mat-icon svgIcon="cvx-person"></mat-icon>
        </div>
      </ng-template>
      <div class="aligned">
        <span class="display-name">{{ user.profile?.displayName }}</span>
        <span class="job-title">{{ user.profile?.jobTitle }}</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
