import { createAction, props } from '@ngrx/store';
import {
  CarryOverObservationWithEstimateViewModel,
  CheckWithMappingsViewModel,
  CheckWithWorkloadEstimatesViewModel,
  DADeliverableFormViewModel,
  DADeliverableImportResultViewModel,
  DADeliverableTemplateTableViewModel,
  DADeliverableViewModel,
  DADeliverableWithMappingsViewModel,
  DADeliverableWithWorkloadEstimatesViewModel,
  DeliverableManagerOverviewViewModel,
  DeliverableMasterViewModel,
  FileParameter,
  ProjectDADeliverableOneOnOneMappingViewModel,
  ProjectDeliverableImportResultViewModel,
  ProjectDeliverableViewModel,
  ProjectDeliverableWithCheckMappingsViewModel,
  ProjectDeliverableWithMappingsViewModel,
  WorkloadEstimateChangeAdditionalCheckViewModel,
  WorkloadEstimateChangeCarryOverObservationViewModel,
  WorkloadEstimateChangeViewModel,
  WorkloadEstimateWithStatisticsAdditionalCheckViewModel,
  WorkloadEstimateWithStatisticsViewModel,
  WorkplanEstimatesPlanViewModel,
} from 'src/app/shared/models/autogenerated';
import { CarryOverObservationWithEstimateAndSmeName } from '../models/carry-over-observation-with-estimate-and-sme-name.model';

export const loadDADeliverable = createAction(
  '[Screening] Load DA Deliverable details',
  props<{ id: string }>()
);

export const clearDADeliverableForm = createAction(
  '[Screening] Clear DA Deliverable Form'
);

export const setDADeliverableForm = createAction(
  '[Screening] Set DA Deliverable Form Success',
  props<{ deliverable: DADeliverableFormViewModel | null }>()
);

export const createDADeliverableFromForm = createAction(
  '[Screening] Create DA Deliverable from form'
);

export const createDADeliverableFromFormSuccess = createAction(
  '[Screening] Create DA Deliverable Success',
  props<{ deliverable: DADeliverableViewModel | null }>()
);

export const updateDADeliverableFromForm = createAction(
  '[Screening] Update DA Deliverable from form',
  props<{ id: string }>()
);

export const updateDADeliverableFromFormSuccess = createAction(
  '[Screening] Update DA Deliverable Success from form',
  props<{ deliverable: DADeliverableViewModel | null }>()
);

export const loadDeliverablesForPhase = createAction(
  '[Screening] Load Deliverables for Phase'
);

export const setDeliverablesForPhase = createAction(
  '[Screening] Deliverables Loaded Success',
  props<{
    deliverables: DADeliverableViewModel[] | null;
  }>()
);

export const loadDeliverablesWithMappingsForPhase = createAction(
  '[Screening] Load Deliverables with mappings for Phase'
);

export const setDeliverablesWithMappingsForPhase = createAction(
  '[Screening] Deliverables with mapping Loaded Success',
  props<{
    deliverables: DADeliverableWithMappingsViewModel[] | null;
  }>()
);

export const setAllDeliverableTemplates = createAction(
  '[Screening] DADeliverableTemplateTable Loaded Success',
  props<{
    deliverableTemplates: DADeliverableTemplateTableViewModel[] | null;
  }>()
);

export const loadAllDeliverableTemplates = createAction(
  '[Screening] Load DADeliverableTemplateTable'
);

export const createDeliverable = createAction(
  '[Screening] Create Deliverable',
  props<{ deliverable: DADeliverableViewModel }>()
);

export const createDeliverableSuccess = createAction(
  '[Screening] Create Deliverable Success'
);

export const createRangeDeliverables = createAction(
  '[Screening] Create Deliverable Range',
  props<{ deliverables: DADeliverableViewModel[] }>()
);

export const createRangeDeliverablesSuccess = createAction(
  '[Screening] Create Deliverable Range Success'
);

export const deleteDeliverable = createAction(
  '[Screening] Delete Deliverable',
  props<{
    deliverable: DADeliverableViewModel;
    reloadMasterList?: boolean;
    reloadTemplates?: boolean;
  }>()
);

export const deleteRangeDeliverables = createAction(
  '[Screening] Delete Deliverables Range',
  props<{ id: string[] }>()
);

export const deleteDeliverableFromEstimatesPage = createAction(
  '[Screening] Delete Deliverable from Estimates page',
  props<{ id: string }>()
);

export const deleteDeliverableFromEstimatesPageSuccess = createAction(
  '[Screening] Delete Deliverable from Estimates page Success'
);

export const loadEstimates = createAction(
  '[Screening] Load Deliverable Estimates'
);

export const loadAllDeliverablesWithEstimates = createAction(
  '[Screening] Load DADeliverablesWithEstimates'
);

export const setAllDeliverablesWithEstimates = createAction(
  '[Screening] Load DADeliverablesWithEstimates Success',
  props<{
    deliverablesWithEstimates: DADeliverableWithWorkloadEstimatesViewModel[];
  }>()
);

export const loadEstimatesStatistics = createAction(
  '[Screening] Load Estimates Statistics'
);

export const setEstimatesStatistics = createAction(
  '[Screening] Load Estimates Statistics Success',
  props<{
    totalEstimates: WorkloadEstimateWithStatisticsViewModel[];
  }>()
);

export const changeWorkEstimate = createAction(
  '[Screening] Change Deliverable Work Estimate',
  props<{
    previous: WorkloadEstimateChangeViewModel;
    next: WorkloadEstimateChangeViewModel;
  }>()
);

export const changeWorkEstimates = createAction(
  '[Screening] Change Deliverable Work Estimates',
  props<{
    changes: WorkloadEstimateChangeViewModel[];
    deliverableTitle: string;
  }>()
);

export const deleteCheckFromEstimatesPage = createAction(
  '[Screening] Delete Check from Estimates page',
  props<{ id: string }>()
);

export const loadEstimatesChecks = createAction(
  '[Screening] Load Check Estimates'
);

export const loadEstimatesChecksWithWorkplanEstimatesPlan = createAction(
  '[Screening] Load Check Estimates with workplan estimates plan'
);

export const loadAllChecksWithEstimates = createAction(
  '[Screening] Load Checks with Estimates'
);

export const setAllChecksWithEstimates = createAction(
  '[Screening] Load Checks with Estimates Success',
  props<{
    checksWithEstimates: CheckWithWorkloadEstimatesViewModel[];
  }>()
);

export const loadCarryOverObservationsWithEstimates = createAction(
  '[Screening] Load Carry-Over Observations Estimates'
);

export const setCarryOverObservationsWithEstimates = createAction(
  '[Screening] Set Carry-Over Observation Estimates',
  props<{
    estimates: CarryOverObservationWithEstimateViewModel[];
  }>()
);

export const setCarryOverObservationsWithEstimatesAndSmeNames = createAction(
  '[Screening] Set Carry-Over Observation Estimates with SME Names',
  props<{
    estimates: CarryOverObservationWithEstimateAndSmeName[];
  }>()
);

export const changeCarryOverObservationEstimate = createAction(
  '[Screening] Change Carry-Over Observation Estimate',
  props<{ estimate: WorkloadEstimateChangeCarryOverObservationViewModel }>()
);

export const loadEstimatesStatisticsAdditionalCheck = createAction(
  '[Screening] Load Estimates Statistics for Additional Check'
);

export const setEstimatesStatisticsAdditionalCheck = createAction(
  '[Screening] Load Estimates Statistics for Additional Check Success',
  props<{
    totalEstimates: WorkloadEstimateWithStatisticsAdditionalCheckViewModel[];
  }>()
);

export const changeWorkEstimateAdditionalCheck = createAction(
  '[Screening] Change Work Estimate for Additional Check',
  props<{
    previous: WorkloadEstimateChangeAdditionalCheckViewModel;
    next: WorkloadEstimateChangeAdditionalCheckViewModel;
  }>()
);

export const changeListWorkEstimateAdditionalCheck = createAction(
  '[Screening] Change List Deliverable Work Estimates for Additional Check',
  props<{
    changes: WorkloadEstimateChangeAdditionalCheckViewModel[];
  }>()
);

export const loadMasterList = createAction('[Screening] Load Master List');

export const setMasterList = createAction(
  '[Screening] Load Master List Success',
  props<{
    masterList: DeliverableMasterViewModel[];
  }>()
);

export const importProjectDeliverablesSuccess = createAction(
  '[Screening] Import Project Deliverables Success',
  props<{
    projectDeliverables: ProjectDeliverableImportResultViewModel | null;
  }>()
);

export const loadProjectDeliverablesWithMappingsForPhase = createAction(
  '[Screening] Load Project Deliverables with DA Deliverable mappings for Phase'
);

export const setProjectDeliverablesWithMappingsForPhase = createAction(
  '[Screening] Project Deliverables with  DA Deliverable mapping Loaded Success',
  props<{
    projectDeliverables: ProjectDeliverableWithMappingsViewModel[] | null;
  }>()
);

export const createProjectDaDeliverableMapping = createAction(
  '[Screening] Create Project Deliverable and DA Deliverable mapping',
  props<{
    projectDeliverableIdList: string[] | null;
    deliverableIdList: string[] | null;
  }>()
);

export const createProjectDaDeliverableOneOnOneMapping = createAction(
  '[Screening] Create Project Deliverable and DA Deliverable One on One mapping',
  props<{
    oneOnOneMappings: ProjectDADeliverableOneOnOneMappingViewModel[] | null;
  }>()
);

export const deleteMappingsForProjectDeliverable = createAction(
  '[Screening] Delete DA Deliverable mappings for the Project Deliverable',
  props<{ projectDeliverableId: string }>()
);

export const deleteMappingsForProjectDeliverableSuccess = createAction(
  '[Screening] Delete DA Deliverable mappings for the Project Success'
);

export const deleteMappingsForDaDeliverable = createAction(
  '[Screening] Delete mappings for the DA Deliverable',
  props<{ daDeliverableId: string }>()
);

export const deleteProjectDaDeliverableMappingsSuccess = createAction(
  '[Screening] Delete mappings between Project Deliverable and DA Deliverable Success'
);

export const loadDeliverableManagerOverview = createAction(
  '[Screening] Load Deliverables Manager Overview for Phase'
);

export const setDeliverableManagerOverview = createAction(
  '[Screening] Load Deliverables Manager Overview for Phase Success',
  props<{
    deliverableManagerOverview: DeliverableManagerOverviewViewModel[] | null;
  }>()
);

export const deliverableManagerQuickFilterChange = createAction(
  '[Deliverable Manager] Quick Filter Changed',
  props<{ quickFilterText: string }>()
);

export const deliverableManagerQuickFilterChangeSuccess = createAction(
  '[Deliverable Manager] Quick Filter Change Success',
  props<{ phaseId: string; quickFilterText: string }>()
);

export const setDeliverableManagerOverviewPageView = createAction(
  '[Deliverable Manager Overview] Set page view',
  props<{ overviewPageView: string }>()
);

export const setDeliverableManagerOverviewPageViewByPhaseId = createAction(
  '[Deliverable Manager Overview] Set page view by phase id',
  props<{ overviewPageView: string; phaseId: any }>()
);

export const loadWorkplanEstimatesPlan = createAction(
  '[Screening] Load Workplan Estimates Plan'
);

export const setWorkplanEstimates = createAction(
  '[Screening] Set Workplan Estimates Plan',
  props<{ workplanEstimatesPlan: WorkplanEstimatesPlanViewModel | null }>()
);

export const setDeliverableDisciplinesValueForm = createAction(
  '[Screening] Set Deliverable Disciplines Value Form',
  props<{ deliverableId: string; hours: number | null }>()
);

export const setDeliverableDisciplinesValue = createAction(
  '[Screening] Set Deliverable Disciplines Value',
  props<{ deliverableId: string; hours: number }>()
);

export const loadProjectDeliverablesWithCheckMappingsForPhase = createAction(
  '[Screening] Load Project Deliverables with Check mappings for Phase'
);

export const setProjectDeliverablesWithCheckMappingsForPhase = createAction(
  '[Screening] Project Deliverables with Check mapping Loaded Success',
  props<{
    projectDeliverables: ProjectDeliverableWithCheckMappingsViewModel[] | null;
  }>()
);

export const loadChecksWithMappingsForPhase = createAction(
  '[Screening] Load Checks with Project Deliverable mappings for Phase'
);

export const setCheckWithMappingsForPhase = createAction(
  '[Screening] Set Checks with Project Deliverable mapping Loaded Success',
  props<{
    checks: CheckWithMappingsViewModel[] | null;
  }>()
);
export const createProjectDeliverableChecksMapping = createAction(
  '[Screening] Create Project Deliverable and Checks mapping',
  props<{
    projectDeliverableIdList: string[] | null;
    checkIdList: string[] | null;
  }>()
);

export const deleteCheckMappingsForProjectDeliverable = createAction(
  '[Screening] Delete Check mappings for the Project Deliverable',
  props<{ projectDeliverableId: string }>()
);

export const deleteMappingsForCheck = createAction(
  '[Screening] Delete project deliverable mappings for Check',
  props<{ checkId: string }>()
);

export const deleteCheckMappingsSuccess = createAction(
  '[Screening] Delete Check mappings for the Project Success'
);

export const loadSMEDisciplineConfigCodes = createAction(
  '[Screening] Load SME Discipline Config Codes'
);

export const loadSMEDisciplineConfigCodesSuccess = createAction(
  '[Screening] Load SME Discipline Config Codes Success',
  props<{ codes: string[] | null }>()
);

export const loadUnmatchedCarryOversProjectDeliverables = createAction(
  '[Screening] Load Unmatched Carry-Overs Project Deliverables'
);

export const setUnmatchedCarryOversProjectDeliverablesSuccess = createAction(
  '[Screening] Set Unmatched Carry-Overs Project Deliverables',
  props<{
    unmatchedCarryOverProjectDeliverables: ProjectDeliverableViewModel[] | null;
  }>()
);

export const importDADeliverablesSuccess = createAction(
  '[Screening] Import DA Deliverables Success',
  props<{
    daDeliverables: DADeliverableImportResultViewModel | null;
  }>()
);

export const importDADeliverables = createAction(
  '[Screening] Import',
  props<{
    file: FileParameter;
    onlyAdd: boolean;
    insertMissing: boolean;
    removeDeleted: boolean;
    updateExisting: boolean;
  }>()
);

export const checkForDADeliverablesImportDuplicates = createAction(
  '[Screening] Check for DA Deliverables Import Duplicates',
  props<{
    file: FileParameter;
  }>()
);

export const daDeliverablesImportDuplicatesSuccess = createAction(
  '[Screening] Open DA Deliverables Import Duplicates Success'
);

export const downloadDADeliverablesImportTemplate = createAction(
  '[Screening] Download the import template file'
);
