import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import { AppState } from 'src/app/app.state';
import { QuickFilterModes } from 'src/app/shared/ag-grid-config/ag-grid-config.constants';
import { GridQuickFilterComponent } from 'src/app/shared/ag-grid-config/components/grid-quick-filter/grid-quick-filter.component';
import {
  quickFilterModeChange,
  quickFilterTagsChange,
} from 'src/app/shared/ag-grid-config/store/ag-grid-config/ag-grid-config.actions';
import { AgGridIconButtonRenderer } from 'src/app/shared/components/ag-grid-icon-button-renderer/ag-grid-icon-button-renderer.component';
import { AgGridIconButtonRendererActions } from 'src/app/shared/models/ag-grid-icon-button-renderer.model';
import { ActionDialogType } from 'src/app/shared/models/enums/action-dialog-type.enum';
import { openActionDialog } from 'src/app/shared/store/action-dialog/action-dialog.actions';
import { AdapCellClassRules } from 'src/app/shared/utils/ag-grid-utils';
import { stopWordsFilter } from 'src/app/shared/utils/string-util';
import { DeliverableManagerComponent } from '../../pages/deliverable-manager/deliverable-manager.component';
import { DELIVERABLE_MANAGER_TABLE_ROUTE_ID } from '../../screening.constants';
import { DeliverableManagerMappingRendererComponent } from '../deliverable-manager-mapping-renderer/deliverable-manager-mapping-renderer.component';

@Component({
  selector: 'app-deliverable-manager-table',
  templateUrl: './deliverable-manager-common-table.component.html',
  styleUrls: ['./deliverable-manager-common-table.component.scss'],
})
export class DeliverableManagerCommonTableComponent<T> {
  @ViewChild(GridQuickFilterComponent)
  deliverableManagerQuickFilterComponent!: GridQuickFilterComponent;
  @ViewChild(AgGridAngular)
  agGrid!: AgGridAngular;
  @Input() rowData!: T[];
  @Input() quickFilter!: string;
  @Input() quickFilterTags!: string[];
  @Output() selectionChanged = new EventEmitter<T[]>();
  @Output() setFilterByDeliverableIds = new EventEmitter<string[]>();
  @Output() quickFilterChanged = new EventEmitter<string>();
  @Output() deselectAllInBothTables = new EventEmitter();
  @Output() filterChanged = new EventEmitter();
  @Output() tableReady = new EventEmitter();

  configStoreTableName: string = '';
  configStoreRouteIdName: string = DELIVERABLE_MANAGER_TABLE_ROUTE_ID;
  areFiltersConnected: boolean = false;

  protected titleToTagsActions?: AgGridIconButtonRendererActions;
  protected tagsColumn: ColDef = {
    minWidth: 36,
    maxWidth: 36,
    filter: false,
    sortable: false,
    colId: 'tags',
    valueGetter: (params) => params.data,
    cellClass: ['ag-button-column-cell', 'ag-center-aligned-cell'],
    cellRenderer: 'agGridIconButton',
  };

  showOnlyIds!: string[] | null;

  frameworkComponents = {
    deliverableManagerMappingRenderer:
      DeliverableManagerMappingRendererComponent,
    agGridIconButton: AgGridIconButtonRenderer,
  };

  defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    cellClassRules: AdapCellClassRules,
    filterParams: {
      newRowsAction: 'keep',
    },
  };

  columnDefs: ColDef[] = [];

  setQuickFilter(filterText: string) {
    this.onQuickFilterChanged(filterText);
    this.deliverableManagerQuickFilterComponent.setFilterText(filterText);
  }

  setQuickFilterTags(filterTags: string[]) {
    this.onQuickFilterTagsChanged(new Set<string>(filterTags));
    this.deliverableManagerQuickFilterComponent.setFilterTags(
      new Set<string>([...filterTags])
    );
  }

  onQuickFilterChanged(filterText: string) {
    this.filterChanged.emit();
    this.quickFilterChanged.emit();
    this.quickFilter = filterText;
    this.updateShowingOnlyIds(null);
  }

  onQuickFilterTagsChanged(filterTags: Set<string>) {
    this.filterChanged.emit();
    this.quickFilterChanged.emit();
    this.quickFilterTags = [...filterTags];
    this.updateShowingOnlyIds(null);
  }

  clearAllFilters() {
    this.agGrid!.api!.setFilterModel(null);
    this.deliverableManagerQuickFilterComponent.onQuickFilterChanged('');
    this.deliverableManagerQuickFilterComponent.onQuickFilterTagsChanged(
      new Set<string>()
    );
  }

  deselectAll() {
    this.agGrid!.api.deselectAll();
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    var selectedRows = event.api!.getSelectedRows();
    this.selectionChanged.emit(selectedRows);
  }

  setShowingOnlyIds(ids: string[]) {
    this.deliverableManagerQuickFilterComponent.setFilterText(
      DeliverableManagerComponent.SHOWING_ONLY_MAPPED_DELIVERABLES
    );

    this.quickFilter = '';
    this.quickFilterTags = [];
    this.updateShowingOnlyIds(ids);
  }

  updateShowingOnlyIds(ids: string[] | null) {
    this.showOnlyIds = ids;
    this.agGrid!.api!.onFilterChanged();
  }

  isDisplayingOnlyIds() {
    return (this.showOnlyIds?.length ?? 0) > 0;
  }

  isExternalFilterPresent() {
    return this.isDisplayingOnlyIds();
  }

  doesExternalFilterPass(row: RowNode) {
    return this.showOnlyIds?.includes(row.data.id) ?? false;
  }

  deleteConfirmationDialog(store: Store<AppState>, onConfirm: () => void) {
    store.dispatch(
      openActionDialog({
        title: 'delete mapping(s)',
        description:
          'Are you sure you want to delete the selected mapping(s)? You cannot undo this action.',
        confirmButtonText: 'delete',
        dialogType: ActionDialogType.DeleteConfirmation,
        onConfirm,
      })
    );
  }

  onFilterChanged() {
    this.filterChanged.emit();
  }

  protected setTagsToTitleActions(
    tooltip: string,
    action: (data: any) => void
  ) {
    this.titleToTagsActions?.setProps({
      style: 'outline',
      disabled: false,
      show: true,
      icon: 'bookmarks',
      tooltip,
      action,
    });
  }

  protected sendTagsToFilter(
    store: Store<AppState>,
    configStoreTableName: string,
    title: string
  ) {
    const filteredTags = stopWordsFilter(title);

    store.dispatch(
      quickFilterTagsChange({
        tableName: configStoreTableName,
        routeIdName: this.configStoreRouteIdName ?? '',
        quickFilterTags: [...filteredTags],
      })
    );

    store.dispatch(
      quickFilterModeChange({
        tableName: configStoreTableName,
        routeIdName: this.configStoreRouteIdName ?? '',
        quickFilterMode: QuickFilterModes.tags,
      })
    );
  }
}
